import { EntitlementValueObject as EntitlementValueObjectDto } from "../../../src/reclaim-api/client";
import { BUILD_ID } from "../../analytics";
import { Override } from "../../types";
import { strToDate } from "../../utils/dates";
import { getBillingUrl } from "../../utils/router";
import { TransformDomain } from "../types";
import { ReclaimEdition } from "../Users";
import {
  DomainSetting as DomainSettingDto,
  JoinableTeam as JoinableTeamDto,
  MembershipRole as MembershipRoleDto,
  PartialTeam as PartialTeamDto,
  PartialTeamInvitation as PartialTeamInvitationDto,
  PartialTeamMember as PartialTeamMemberDto,
  ReclaimApi,
  ReclaimEdition as ReclaimEditionDto,
  RequestParams,
  TeamDomainView as TeamDomainViewDto,
  TeamInvitation as TeamInvitationDto,
  TeamInvoice as TeamInvoiceDto,
  TeamJoinResponse as TeamJoinResponseDto,
  TeamMember as TeamMemberDto,
  TeamMembershipSummary as TeamMembershipSummaryDto,
  TeamMemberView as TeamMemberViewDto,
  TeamSettings as TeamSettingsDto,
  TeamView as TeamViewDto,
} from "./client";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

/**************************
 * START ENTITLEMENT DEFS *
 **************************/

export type ReclaimEditionStr = `${ReclaimEditionDto}`;
export type ReclaimEditionV2 = Extract<ReclaimEditionStr, "LITE" | "STARTER" | "BUSINESS" | "ENTERPRISE">;

export const RECLAIM_EDITION_META: Record<ReclaimEditionStr, { label: string; isTrial?: true }> = {
  ASSISTANT: { label: "Assistant" },
  BUSINESS: { label: "Business" },
  ENTERPRISE: { label: "Enterprise" },
  LEGACY_PRO_TRIAL: { label: "Pro", isTrial: true },
  LEGACY_TEAM_TRIAL: { label: "Team", isTrial: true },
  LITE: { label: "Lite" },
  NONE: { label: "" },
  PRO: { label: "Pro" },
  REWARD: { label: "Team" },
  STARTER: { label: "Starter" },
  TEAM: { label: "Team" },
  TRIAL: { label: "Team", isTrial: true },
  TRIAL_BUSINESS: { label: "Business", isTrial: true },
};

export type EntitlementValueObject<T> = {
  value: T;
} & (
  | {}
  | {
      nextValue: T;
      nextEdition: ReclaimEditionV2;
    }
);

export type EntitlementIntegrations = "ESSENTIAL" | "PREMIUM";
export type EntitlementSupport = "COMMUNITY" | "BASIC" | "PRIORITY";

export type EntitlementTableRow = {
  PRICE_PER_MONTH: EntitlementValueObject<number>;
  MAX_SIZE: EntitlementValueObject<number>;
  SCHEDULER_WEEKS: EntitlementValueObject<number>;
  MAX_TASKS: EntitlementValueObject<number>;
  MAX_CALENDARS: EntitlementValueObject<number>;
  MAX_SYNCS: EntitlementValueObject<number>;
  MAX_HABITS: EntitlementValueObject<number>;
  CUSTOM_BLOCKING: EntitlementValueObject<boolean>;
  MAX_SCHEDULING_LINKS: EntitlementValueObject<number>;
  MAX_1_ON_1_ORGANIZE: EntitlementValueObject<number>;
  MAX_1_ON_1_ATTEND: EntitlementValueObject<number>;
  INTEGRATIONS: EntitlementValueObject<EntitlementIntegrations>;
  SUPPORT: EntitlementValueObject<EntitlementSupport>;
  SSO: EntitlementValueObject<boolean>;
  MAX_DOMAIN_USERS: EntitlementValueObject<number>;
};

export type EntitlementType = keyof EntitlementTableRow;
export type EntitlementTable = Record<ReclaimEditionV2, EntitlementTableRow>;

export const dtoToEntitlementValueObject = <T>(dto: EntitlementValueObjectDto): EntitlementValueObject<T> => ({
  value: dto.value as unknown as T,
  nextValue: dto.nextValue as unknown as T,
  nextEdition: dto.nextEdition as ReclaimEditionV2,
});

export const dtoToNumberEntitlementValueObject = (dto: EntitlementValueObjectDto): EntitlementValueObject<number> => ({
  value: dto.value === null ? Infinity : (dto.value as unknown as number),
  nextValue: dto.value === null ? Infinity : (dto.value as unknown as number),
  nextEdition: dto.nextEdition as ReclaimEditionV2,
});

export const dtoToEntitlementTableRow = (dto: Record<string, EntitlementValueObjectDto>): EntitlementTableRow => ({
  PRICE_PER_MONTH: dtoToNumberEntitlementValueObject(dto.PRICE_PER_MONTH),
  MAX_SIZE: dtoToNumberEntitlementValueObject(dto.MAX_SIZE),
  SCHEDULER_WEEKS: dtoToNumberEntitlementValueObject(dto.SCHEDULER_WEEKS),
  MAX_TASKS: dtoToNumberEntitlementValueObject(dto.MAX_TASKS),
  MAX_CALENDARS: dtoToNumberEntitlementValueObject(dto.MAX_CALENDARS),
  MAX_SYNCS: dtoToNumberEntitlementValueObject(dto.MAX_SYNCS),
  MAX_HABITS: dtoToNumberEntitlementValueObject(dto.MAX_HABITS),
  CUSTOM_BLOCKING: dtoToEntitlementValueObject<boolean>(dto.CUSTOM_BLOCKING),
  MAX_SCHEDULING_LINKS: dtoToNumberEntitlementValueObject(dto.MAX_SCHEDULING_LINKS),
  MAX_1_ON_1_ORGANIZE: dtoToNumberEntitlementValueObject(dto.MAX_1_ON_1_ORGANIZE),
  MAX_1_ON_1_ATTEND: dtoToNumberEntitlementValueObject(dto.MAX_1_ON_1_ATTEND),
  INTEGRATIONS: dtoToEntitlementValueObject<EntitlementIntegrations>(dto.INTEGRATIONS),
  SUPPORT: dtoToEntitlementValueObject<EntitlementSupport>(dto.SUPPORT),
  SSO: dtoToEntitlementValueObject<boolean>(dto.SSO),
  MAX_DOMAIN_USERS: dtoToNumberEntitlementValueObject(dto.MAX_DOMAIN_USERS),
});

export const dtoToEntitlementTable = (
  dto: Record<string, Record<string, EntitlementValueObjectDto>>
): EntitlementTable =>
  (Object.entries(dto) as [ReclaimEditionV2, Record<EntitlementType, EntitlementValueObjectDto>][]).reduce(
    (table, [edition, dtoRow]) => {
      table[edition] = dtoToEntitlementTableRow(dtoRow);
      return table;
    },
    {} as EntitlementTable
  );

/**************************
 *  END ENTITLEMENT DEFS  *
 **************************/

export type TeamInvoice = Override<TeamInvoiceDto, {}>;
export const STRIPE_SESSION_URI = `${API_BASE_URI}/team/current/subscription/session`;
export const STRIPE_NEW_SESSION_URI = `${API_BASE_URI}/team/create/subscription/session`;

export enum InvitationStatus {
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  Deleted = "DELETED",
}

export enum TeamMemberViewStatus {
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
}

export enum MembershipRole {
  User = "USER",
  Admin = "ADMIN",
}

export const MembershipRoleLabel: Record<MembershipRole, string> = {
  ADMIN: "Admin",
  USER: "User",
};

export enum SubscriptionStatus {
  Trialing = "TRIALING",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum DomainSetting {
  InviteOnly = "INVITE_ONLY",
  Request = "REQUEST",
  Open = "OPEN",
  AutoDomainCapture = "AUTO_DOMAIN_CAPTURE",
}

export enum RequestedTeamStatus {
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export enum InviteSource {
  Default = "invite",
  CreateTeam = "create-team",
}

export type TeamMemberArchetype = Override<
  TeamMemberDto,
  {
    readonly edition: ReclaimEdition;
    readonly editionAfterTrial: ReclaimEdition;
    readonly role: MembershipRole;
  }
>;

export type TeamMember = Override<
  TeamMemberViewDto,
  {
    readonly edition: ReclaimEdition;
    readonly editionAfterTrial: ReclaimEdition;
    readonly status: TeamMemberViewStatus;
    readonly membershipRole: MembershipRole;
    readonly trialEnd?: Date;
    readonly requestedTeamStatus?: RequestedTeamStatus;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly name?: string;
    readonly editionUsage: ReclaimEditionStr;
    readonly paidSeat?: boolean | null;
    readonly trialSeat?: boolean | null;
    // This field is expected based on the swagger definition but
    // it can actually be undefined if the user hasn't onboarded with
    // scheduling links. TODO: RAI-6959
    readonly schedulingLinkSlug?: string;
  }
>;

export type TeamSettings = Override<TeamSettingsDto, {}>;

export type Team<HOMOGENEOUS extends boolean = boolean> = Override<
  Omit<TeamViewDto, "homogeneous" | "teamEdition" | "entitlements">,
  {
    readonly id?: number;
    /**
     * @deprecated
     */
    readonly userInviteLevel: ReclaimEdition;
    readonly subscriptionStatus: SubscriptionStatus;
    readonly paidProSeats: number;
    readonly paidTeamSeats: number;
    readonly proSeatsUsed: number;
    readonly teamSeatsUsed: number;
    readonly members: TeamMember[];
    readonly domainSetting: DomainSetting;
    readonly teamSettings: TeamSettings;
    readonly membershipSummary: TeamMembershipSummary;
  }
> &
  (HOMOGENEOUS extends true
    ? {
        readonly homogeneous: true;
        readonly teamEdition: ReclaimEditionV2;
        readonly entitlements: EntitlementTableRow;
      }
    : {
        readonly homogeneous: false;
      });

export type PartialTeam = Override<
  PartialTeamDto,
  {
    userInviteLevel?: ReclaimEdition | null;
    domainSetting?: DomainSetting;
  }
>;

export type JoinableTeam = Override<
  JoinableTeamDto,
  {
    readonly domainSetting: DomainSetting;
  }
>;

export type TeamMembershipSummary = Override<TeamMembershipSummaryDto, {}>;

export type TeamJoinResponse = Override<
  TeamJoinResponseDto,
  {
    role?: MembershipRole;
    editionAfterTrial?: ReclaimEdition;
  }
>;

export type PartialTeamMember = Override<
  PartialTeamMemberDto,
  {
    edition?: ReclaimEdition;
    role?: MembershipRole;
  }
>;

export type PartialTeamInvitation = Override<
  PartialTeamInvitationDto,
  {
    role: MembershipRole;
  }
>;

export type TeamInvitation = TeamInvitationDto;

export type TeamDomainView = TeamDomainViewDto;

export enum SubscriptionFrequency {
  Month = "MONTH",
  Year = "YEAR",
}

export type DesiredSubscription = { frequency: SubscriptionFrequency; seats: number; edition: ReclaimEdition };

export enum TeamRedirectAction {
  InviteAccepted = "accepted",
  PurchaseSuccess = "purchased",
  PurchaseCancelled = "purchaseCancelled",
  StartPurchase = "startPurchase",
}

export const dtoToTeamMembershipSummary = (dto: TeamMembershipSummaryDto): TeamMembershipSummary => dto;

export const dtoToTeamMember = (
  dto: TeamMemberViewDto & { firstName?: string; lastName?: string; name?: string }
): TeamMember => {
  const [dtoFirstName, ...otherNames] = (dto.name || "").split(" ");
  const dtoLastName = otherNames[otherNames.length - 1];

  return {
    ...dto,
    edition: ReclaimEdition.get(dto.edition) || ReclaimEdition.Free,
    editionAfterTrial: ReclaimEdition.get(dto.editionAfterTrial || undefined) || ReclaimEdition.Free,
    status: dto.status as unknown as TeamMemberViewStatus,
    membershipRole: dto.membershipRole as unknown as MembershipRole,
    editionUsage: dto.editionUsage || "ASSISTANT",
    requestedTeamStatus: !!dto.requestedTeamStatus
      ? (dto.requestedTeamStatus as unknown as RequestedTeamStatus)
      : undefined,
    trialEnd: strToDate(dto.trialEnd),
    firstName: dto.firstName || dtoFirstName,
    lastName: dto.lastName || dtoLastName,
  };
};

export const dtoToTeamMemberArchetype = (dto: TeamMemberDto): TeamMemberArchetype => {
  return {
    ...dto,
    edition: ReclaimEdition.get(dto.edition) || ReclaimEdition.Free,
    editionAfterTrial: ReclaimEdition.get(dto.editionAfterTrial) || ReclaimEdition.Free,
    role: dto.role as unknown as MembershipRole,
  };
};

export const dtoToTeam = (dto: TeamViewDto): Team => {
  return {
    ...dto,
    subscriptionStatus: dto.subscriptionStatus as unknown as SubscriptionStatus,
    paidProSeats: dto.paidProSeats || 0,
    paidTeamSeats: dto.paidTeamSeats || 0,
    proSeatsUsed: dto.proSeatsUsed || 0,
    teamSeatsUsed: dto.teamSeatsUsed || 0,
    userInviteLevel: ReclaimEdition.get(dto.userInviteLevel) || ReclaimEdition.None,
    members: dto.members?.map(dtoToTeamMember),
    domainSetting: dto.domainSetting as unknown as DomainSetting,
    teamEdition: (dto.teamEdition || undefined) as ReclaimEditionV2,
    entitlements: (dto.entitlements && dtoToEntitlementTableRow(dto.entitlements)) as EntitlementTableRow,
    membershipSummary: dtoToTeamMembershipSummary(dto.membershipSummary),
  };
};

export const dtoToJoinableTeams = (teams: JoinableTeamDto[]): JoinableTeam[] =>
  teams.map((team: JoinableTeamDto) => ({
    ...team,
    domainSetting: team.domainSetting as unknown as DomainSetting,
  }));

export const responsesToDto = (responses: TeamJoinResponse[]): TeamJoinResponseDto[] =>
  responses.map((r) => ({
    ...r,
    role: r.role as unknown as MembershipRoleDto,
    editionAfterTrial: r.editionAfterTrial as unknown as ReclaimEditionDto,
  }));

export const dtoToTeamInvoice = (dto: TeamInvoiceDto): TeamInvoice => {
  return {
    ...dto,
  };
};

export const invitesToDto = (invites: PartialTeamInvitation[]): PartialTeamInvitationDto[] => {
  return invites.map((i) => ({
    ...i,
    role: i.role as unknown as MembershipRoleDto,
  }));
};

export const partialTeamMemberToDto = (member: PartialTeamMember): PartialTeamMemberDto => ({
  ...member,
  role: member.role as unknown as MembershipRoleDto,
  edition: member.edition as unknown as ReclaimEditionDto,
});

export const partialTeamToDto = (team: PartialTeam): PartialTeamDto => ({
  ...team,
  userInviteLevel: !!team.userInviteLevel
    ? (team.userInviteLevel as unknown as ReclaimEditionDto)
    : team.userInviteLevel,
  domainSetting: team.domainSetting as unknown as DomainSettingDto,
});

export class TeamDomain extends TransformDomain<Team, TeamViewDto> {
  /**
   * The team domain currently has its own separate client generation. Use
   * the domainApi instead of api for executing team module requests.
   */
  domainApi: ReclaimApi;

  constructor(...args) {
    super(...args);

    this.domainApi = new ReclaimApi({ baseUrl: API_BASE_URI, BUILD_ID });
  }

  resource = "Team";
  cacheKey = "team";

  get = (): Promise<Team> => this.domainApi.team.getCurrentTeam().then(dtoToTeam);

  getMembers = (): Promise<TeamMember[]> =>
    this.domainApi.team.getMembers().then((response: TeamMemberViewDto[]) => response.map(dtoToTeamMember));

  getJoinableTeams = (): Promise<JoinableTeam[]> => this.domainApi.team.getJoinableTeams().then(dtoToJoinableTeams);

  respondToRequests = (responses: TeamJoinResponse[]): Promise<TeamMemberArchetype[]> =>
    this.domainApi.team.joinResponses(responsesToDto(responses)).then((r) => r.map(dtoToTeamMemberArchetype));

  requestToJoinTeam = (teamId: number) => this.domainApi.team.requestToJoinTeam(teamId);

  getRequests = (): Promise<TeamMemberArchetype[]> =>
    this.domainApi.team.getRequests().then((r) => r.map(dtoToTeamMemberArchetype));

  cancelRequestToJoinTeam = (teamId: number): Promise<void> => this.domainApi.team.cancelRequestToJoinTeam(teamId);

  getInviteableTeamMembers = (): Promise<TeamMemberArchetype[]> =>
    this.domainApi.team.getInviteableTeamMembers().then((r) => r.map(dtoToTeamMemberArchetype));

  listInvoices = (): Promise<TeamInvoice[]> => this.domainApi.team.listInvoices().then((r) => r.map(dtoToTeamInvoice));

  deleteMember = (userId: string) => this.domainApi.team.deleteMember({ userId });

  invite = async (invites: PartialTeamInvitation[], source?: InviteSource) => {
    const dtoinvites = invitesToDto(invites);
    return this.typedManageErrors(() => this.domainApi.team.createInvitation(dtoinvites, { source }))();
  };

  leaveTeam = () => this.domainApi.team.leaveTeam();

  patchTeam = (data: PartialTeam, params?: RequestParams) =>
    this.domainApi.team.patchTeam(partialTeamToDto(data), params);

  patchMember = (userId: string, data: PartialTeamMember, params?: RequestParams) =>
    this.domainApi.team.patchMember({ userId }, partialTeamMemberToDto(data), params);

  getInvitations = (): Promise<TeamInvitation[]> => this.domainApi.team.getInvitations();

  getDomains = (): Promise<TeamDomainView[]> => this.domainApi.team.getCurrentTeamDomains();

  acceptInvitation = (teamId: number, inviteId: string) =>
    this.domainApi.team.acceptInvitation(teamId, inviteId, { accept: true });

  deleteInvitation = (id: string) => this.domainApi.team.deleteInvitation(id);

  startSubscription = ({ frequency, seats, edition }: DesiredSubscription, newTab?: boolean) => {
    const baseRedirect = `${window.location.origin}${getBillingUrl()}`;
    const url = new URL(STRIPE_NEW_SESSION_URI);

    url.search = new URLSearchParams({
      successUrl: `${baseRedirect}?action=${TeamRedirectAction.PurchaseSuccess}`,
      cancelUrl: `${baseRedirect}?action=${TeamRedirectAction.PurchaseCancelled}`,
      seats: seats.toString(),
      edition: edition.key,
      frequency,
    }).toString();

    if (!!newTab) {
      window.open(url.toString(), "billing_subscription");
    } else {
      window.location.href = url.toString();
    }
  };

  getEntitlementTable = this.typedManageErrors(async () => dtoToEntitlementTable(await this.domainApi.team.editions()));
}
